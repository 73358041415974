<template>
  <div class="bbr-plans--archived-plans">
    <plans-list-table
      :loading="loading"
      :plans="list"
      :meta="listMeta"
      :sorting="archivedPlansReordering"
      list-for="Archived"
      @loadMore="loadMore"
      @show="showInformations"
    />
  </div>
</template>

<script>
import PlansListTable from '../components/PlansListTable'
import { mapState, mapMutations } from 'vuex'
import Event from '@/services/eventBus'

export default {
  name: 'ArchivedPlans',

  props: {
    isLoading: {
      type: Boolean,
    },
  },

  components: {
    PlansListTable,
  },

  data() {
    return {
      loading: this.isLoading,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.plans.filter,
      list: (state) => state.plans.archived.list,
      listMeta: (state) => state.plans.archived.listMeta,
      archivedPlansReordering: (state) => state.plans.archived.reordering,
    }),

    currentListType() {
      return this.$route.meta.type
    },
  },

  created() {
    this.initialize()

    Event.$on('update-archived-plans-list', () => {
      this.clearList(this.currentListType)
      this.fetchPlans()
    })
  },

  methods: {
    ...mapMutations({
      setFilter: 'plans/setFilter',
      clearList: 'plans/clearCurrentList',
    }),

    initialize() {
      this.fetchPlans()
    },

    loadMore(nextPage) {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchPlans(nextPage)
      }
    },

    fetchPlans(page = 1) {
      if (this.loading) return

      this.$emit('fetch', page)
    },

    showInformations(planId) {
      this.$router.push({
        name: 'plan.informations',
        params: { id: planId },
      })
    },
  },

  watch: {
    isLoading(value) {
      this.loading = value
    },
  },
}
</script>
